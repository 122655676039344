import { Button, Container, Nav, Navbar } from "react-bootstrap";

export interface HeaderProps {
  showBackgroundMap: boolean;
  setShowBackgroundMap: (value: boolean) => void;
}

export const Header: React.FC<HeaderProps> = ({
  showBackgroundMap,
  setShowBackgroundMap
}) => (
  <Navbar bg="light" expand="lg">
    <Container>
      <Navbar.Brand>Peril Map Editor</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Item
            as={Button}
            onClick={() => setShowBackgroundMap(!showBackgroundMap)}
          >
            {`${showBackgroundMap ? 'Hide' : 'Show'} Background Map`}
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
);
