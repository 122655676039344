import { Vector2d } from "konva/lib/types";

export const alaska: Vector2d[] = [
  {x: 319, y: 259},
  {x: 316, y: 256},
  {x: 316, y: 250},
  {x: 309, y: 246},
  {x: 309, y: 238},
  {x: 304, y: 233},
  {x: 304, y: 225},
  {x: 295, y: 223},
  {x: 292, y: 219},
  {x: 285, y: 219},
  {x: 282, y: 215},
  {x: 276, y: 214},
  {x: 269, y: 207},
  {x: 265, y: 208},
  {x: 264, y: 212},
  {x: 259, y: 214},
  {x: 246, y: 214},
  {x: 251, y: 207},
  {x: 246, y: 204},
  {x: 235, y: 207},
  {x: 232, y: 215},
  {x: 224, y: 218},
  {x: 213, y: 227},
  {x: 207, y: 228},
  {x: 201, y: 236},
  {x: 195, y: 238},
  {x: 192, y: 240},
  {x: 185, y: 235},
  {x: 188, y: 232},
  {x: 193, y: 231},
  {x: 197, y: 225},
  {x: 205, y: 222},
  {x: 207, y: 218},
  {x: 213, y: 211},
  {x: 209, y: 210},
  {x: 202, y: 214},
  {x: 194, y: 214},
  {x: 192, y: 205},
  {x: 186, y: 203},
  {x: 185, y: 197},
  {x: 176, y: 196},
  {x: 185, y: 186},
  {x: 186, y: 177},
  {x: 191, y: 174},
  {x: 198, y: 175},
  {x: 204, y: 178},
  {x: 213, y: 168},
  {x: 201, y: 170},
  {x: 197, y: 165},
  {x: 196, y: 151},
  {x: 201, y: 147},
  {x: 213, y: 148},
  {x: 213, y: 152},
  {x: 220, y: 153},
  {x: 223, y: 148},
  {x: 218, y: 146},
  {x: 216, y: 137},
  {x: 210, y: 129},
  {x: 222, y: 125},
  {x: 232, y: 119},
  {x: 241, y: 119},
  {x: 249, y: 114},
  {x: 256, y: 115},
  {x: 267, y: 122},
  {x: 273, y: 122},
  {x: 279, y: 126},
  {x: 289, y: 127},
  {x: 301, y: 132},
  {x: 297, y: 198},
  {x: 314, y: 198},
  {x: 322, y: 212},
  {x: 321, y: 230},
  {x: 326, y: 235},
  {x: 330, y: 236},
  {x: 331, y: 247},
  {x: 325, y: 247},
  {x: 324, y: 254}
];
