import { useState } from 'react';
import { Layer, Line, Stage } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { Vector2d } from 'konva/lib/types';
import map from './map.png';
import { alaska } from './points';
import { Header } from './Header';

const App: React.FC = () => {
  const [points, setPoints] = useState<Vector2d[]>(alaska);
  const [showBackgroundMap, setShowBackgroundMap] = useState(true);

  const handleMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    const pos = e.target.getStage()!.getPointerPosition()!;
    setPoints([...points, pos]);
  };

  return (
    <>
      <Header showBackgroundMap={showBackgroundMap} setShowBackgroundMap={setShowBackgroundMap} />
      {showBackgroundMap && (
        <img src={map} alt='map' style={{ position: 'absolute', width: 1920, height: 1080 }} />
      )}
      <Stage width={1920} height={1080} onMouseDown={handleMouseDown}>
        <Layer>
          {!!points.length && (
            <Line
              points={points.reduce(
                (prev, {x, y}) => [...prev, x, y],
                [] as number[])}
              closed
              fill="blue"
            />
          )}
        </Layer>
      </Stage>
    </>
  );
}

export default App;
